import styled from "styled-components";

import { VideoSectionStyledProps as Props } from "./VideoSection.types";
import CONSTANTS from "config/constants";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const VideoSectionStyled = styled.div<Props>`
  padding: 4.8rem 7.4rem;
  background-color: var(--palette-h265s10l15a1);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-areas: ". content .";

  ${props =>
    props.anon && "display:flex; align-items: center; justify-content: center;"}

  @media (max-width: ${tablet}px) {
    padding: 2.4rem 3.6rem;
    padding-bottom: 6.4rem;
    border-bottom-left-radius: 4rem;
    border-bottom-right-radius: 4rem;
  }

  @media (max-width: ${mobile}px) {
    padding: 2.4rem 0;
  }

  .VideoSection {
    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-area: content;
      width: 100%;

      @media (max-width: ${tablet}px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(auto-fill);
      }
      ${props =>
        props.anon &&
        "display:flex; align-items: center; justify-content: center;"}
    }

    &__button {
      border-radius: 0.8rem;
      padding: 1.6rem 2rem;

      @media (max-width: ${tablet}px) {
        width: 80%;
      }

      .Button__wrapper {
        font-size: 1.6rem;
        font-weight: 600;
      }
    }

    &__instruction {
      flex: 1;
      @media (max-width: ${tablet}px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      ${props =>
        props.anon &&
        "display: flex; align-items: center; justify-content: center;flex-direction: column;"}
      & > * {
        margin-bottom: 1.6rem;
      }
    }

    &__title {
      font-size: 4.8rem;
      font-weight: 400;
      color: var(--palette-white);

      @media (max-width: ${tablet}px) {
        font-size: 2.6rem;
        text-align: center;
        padding: 0 2.4rem;
      }
    }

    &__description {
      font-size: 1.4rem;
      font-weight: 400;
      color: var(--palette-h0s0l95a1);

      @media (max-width: ${tablet}px) {
        padding: 0 2.4rem;
        text-align: center;
      }
    }

    &__video {
      aspect-ratio: 4/3;
      @media (max-width: ${tablet}px) {
        grid-row-start: 1;
      }
    }

    &__play {
      left: 28%;
      position: relative;
      bottom: 110%;
      max-width: 6rem;
      cursor: pointer;
    }

    &__video {
      @media (max-width: ${tablet}px) {
        display: none;
      }
    }
  }
`;

export default VideoSectionStyled;
