/* eslint-disable no-template-curly-in-string */
import styled from "styled-components";
import { Modal } from "artisn-ui-react";

import { MediumModalStyledProps as Props } from "./MediumModal.types";
import { getMaxHeight, getMaxWidth } from "utils/styling.utils";

const MediumModalStyled = styled(Modal)<Props>`
  ${props =>
    !props.footer
      ? "width: min(${getMaxWidth()}, ${props => (props.discard ? '36rem' : '60rem')});"
      : null}
  ${props =>
    !props.footer
      ? "height: min(${getMaxHeight()},${props => (props.discard ? '46rem' : '60rem')});"
      : null}

  @media all and (hover: none) {
    width: ${getMaxWidth()};
    height: ${getMaxHeight()};
  }

  .MediumModal {
    &__body {
      display: flex;
    }
  }
`;

export default MediumModalStyled;
