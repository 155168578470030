import React from "react";
import { Select } from "antd";
import { useRouter } from "next/router";

import Styles from "./Footer.styles";
import { FooterProps as Props } from "./Footer.types";
import useI18N from "contexts/i18N/i18N.hooks";
import useI18n from "hooks/useI18n";
import useTalkShop from "contexts/talkShop/talkShop/talkShop.context.hooks";
import CONSTANTS from "config/constants";

import Logo from "../Logo/Logo";

const { WITH_TALK_SHOP } = CONSTANTS.FEATURE_FLAGS;
const { Option } = Select;

const Footer: React.FC<Props> = props => {
  const { className } = props;
  const { push } = useRouter();
  const { selectedLanguage, setSelectedLanguage } = useI18N();
  const t = useI18n().Footer;
  const t_h = useI18n().global.LandingNavbar;
  const { talkShopProvider } = useTalkShop();

  const navigateProducts = () => {
    push("products");
  };

  const navigateAlliance = () => {
    push("alliance");
  };

  const navigateTerms = () => {
    push("terms");
  };

  if (talkShopProvider && WITH_TALK_SHOP) return null;

  return (
    <Styles className={`Footer ${className}`}>
      <div className="Footer__content">
        <div className="Footer__top">
          <Logo />
          <div className="Footer__linkWrapper">
            <div className="Footer__linkColumn">
              <div className="Footer__link" onClick={navigateProducts}>
                {t_h.prices}
              </div>
              <div className="Footer__link" onClick={navigateAlliance}>
                {t_h.alliances}
              </div>
              {/* <div className="Footer__link">{t.knowUs}</div>
              <div className="Footer__link">{t.workWithUs}</div>
              <div className="Footer__link">{t.contactUs}</div> */}
            </div>
            <div className="Footer__linkColumn">
              <div className="Footer__link" onClick={navigateTerms}>
                {t.terms}
              </div>
              {/* <div className="Footer__link">{t.help}</div> */}
            </div>
          </div>
        </div>

        <div className="Footer__bottom">
          <div className="Footer__rights">© 2022 Arranca tu negocio</div>
          <Select
            className="Footer__selectLang"
            defaultValue={selectedLanguage}
            onChange={value => {
              setSelectedLanguage(value);
            }}
          >
            <Option value="es">Español (es)</Option>
            <Option value="en">English (en)</Option>
          </Select>
        </div>
      </div>
    </Styles>
  );
};

Footer.defaultProps = {
  className: ""
};

export default Footer;
