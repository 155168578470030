import styled from "styled-components";

import { InstructionsSectionStyledProps as Props } from "./InstructionsSection.types";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const InstructionsSectionStyled = styled.div<Props>`
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-areas: ". content .";

  @media (max-width: ${mobile}px) {
    display: flex;
  }

  .InstructionsSection {
    &__content {
      grid-area: content;
    }

    &__list {
      margin-left: 2.6rem;
    }

    &__descriptionText {
      color: var(--palette-h0s0l40a1);
    }

    &__secondParagraph {
      color: var(--palette-h0s0l40a1);
      margin-top: 1.6rem;
    }
  }
`;

export default InstructionsSectionStyled;
