import styled from "styled-components";

import { VideoModalStyledProps as Props } from "./VideoModal.types";

const VideoModalStyled = styled.div<Props>`
  background: #1f1c21;
  .VideoModal {
    &__closer {
      position: absolute;
      cursor: pointer;
      left: 96%;
      filter: invert(100%) sepia(3%) saturate(167%) hue-rotate(221deg)
        brightness(113%) contrast(89%);
      top: 2%;
    }
  }
`;

export default VideoModalStyled;
