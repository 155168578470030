import React, { useState } from "react";
import useI18n from "hooks/useI18n";

import Styles from "./Country.styles";
import { CountryProps as Props } from "./Country.types";

import MapSVG from "../../../public/assets/images/mapCountry.svg";
import EcSVG from "../../../public/assets/images/mapCountryCOL.svg";
import ArSVG from "../../../public/assets/images/mapCountryAR.svg";
import MxSVG from "../../../public/assets/images/mapCountryMX.svg";
import PeSVG from "../../../public/assets/images/mapCountryPE.svg";
import EllipseSVG from "../../../public/assets/images/locationEllipse.svg";

const Country: React.FC<Props> = props => {
  const [selected, setSelected] = useState(1);
  const t = useI18n();
  const countryCode = ["ec", "ar", "mx", "pe", "col"].sort();
  const countryName = [
    "Ecuador",
    "Argentina",
    "Mexico",
    "Peru",
    "Colombia"
  ].sort();

  return (
    <Styles className="Country">
      <div className="Country__country">
        <div className="Country__helper"></div>
        <MapSVG className="Country__mainMap" />
        <div
          className={`Country__location Country__location--${
            countryCode[selected - 1]
          }`}
        >
          <p className="Country__name">{countryName[selected - 1]}</p>
          <EllipseSVG className="Country__locationEllipse" />
        </div>
        <EcSVG
          className={
            selected === 2 || selected === 3
              ? "Country__selected"
              : "Country__countries"
          }
        />
        <ArSVG
          className={
            selected === 1 ? "Country__selected" : "Country__countries"
          }
        />
        <MxSVG
          className={
            selected === 4 ? "Country__selected" : "Country__countries"
          }
        />
        <PeSVG
          className={
            selected === 5 ? "Country__selected" : "Country__countries"
          }
        />
      </div>
      <div className="Country__description">
        <div className="Country__descriptionColumn">
          <div className="Country__descriptionSlogan">{t.Country.slogan}</div>
          <h1 className="Country__descriptionTitle">{t.Country.title}</h1>
          <div className="Country__descriptionText">{t.Country.subtitle}</div>
        </div>
        <div className="Country__countrySelection">
          {countryName.map((country, index) => (
            <div
              className={
                selected === index + 1
                  ? "Country__countryName Country__countryName--selected"
                  : "Country__countryName"
              }
              onClick={() => setSelected(index + 1)}
              key={index}
            >
              {country}
            </div>
          ))}
        </div>
      </div>
    </Styles>
  );
};

Country.defaultProps = {};

export default Country;
