import styled from "styled-components";

import { CountryStyledProps as Props } from "./Country.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet, wide } = BREAKPOINTS;

const CountryStyled = styled.div<Props>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 1.6rem;
  @media (max-width: ${wide}px) {
    flex-direction: column;
  }

  .Country {
    &__description {
      padding: 8rem;
      display: flex;
      flex: 1;
      flex-direction: column;
      width: auto;
      height: auto;
      @media (max-width: ${tablet}px) {
        flex-direction: column-reverse;
        padding: 0.8rem;
      }
    }

    &__descriptionColumn {
      display: flex;
      flex-direction: column;
      @media (max-width: ${tablet}px) {
        margin-top: 2rem;
      }
    }

    &__descriptionSlogan {
      font-weight: 600;
      font-size: 1.6rem;
      margin-bottom: 2rem;
      text-align: justify;
    }

    &__descriptionTitle {
      font-weight: 400;
      font-size: 2.4rem;
      margin-bottom: 2rem;
      text-align: justify;
    }

    &__descriptionText {
      font-weight: 500;
      font-size: 1.4rem;
      text-align: justify;
      width: 56rem;
      color: var(--palette-h0s0l50a1);

      @media (max-width: ${tablet}px) {
        width: 96%;
      }
    }

    &__country {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 2rem;
      width: auto;
      flex: 1;
      @media (max-width: ${tablet}px) {
        padding: 0rem;
      }
    }

    &__descriptionSlogan {
      margin-bottom: 2rem;
      font-weight: 600;
      font-size: 1.6rem;
    }

    &__descriptionTitle {
      margin-bottom: 2rem;
      font-weight: 400;
      font-size: 2.4rem;
    }

    &__descriptionText {
      font-weight: 500;
      font-size: 1.4rem;
    }

    &__countrySelection {
      display: flex;
      flex-direction: column;
      margin-top: 6.4rem;
      height: auto;
    }

    &__countryName {
      font-weight: 500;
      font-size: 6.4rem;
      cursor: pointer;
      color: var(--palette-h0s0l50a1);

      @media (max-width: ${tablet}px) {
        font-size: 3.2rem;
      }

      &--selected {
        color: var(--palette-h270s10l15a1);
      }
    }

    &__mainMap {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &__countries {
      position: absolute;
      z-index: 1;
      opacity: 0.4;
      width: 100%;
      height: 100%;
    }

    &__selected {
      position: absolute;
      z-index: 2;
      opacity: 1;
      filter: invert(28%) sepia(75%) saturate(331%) hue-rotate(146deg)
        brightness(94%) contrast(94%);
      width: 100%;
      height: 100%;
    }

    &__location {
      background-color: var(--palette-white);
      border-radius: 3.2rem;
      display: flex;
      box-shadow: 0 0.4rem 2rem 2rem var(--palette-h0s0l20a040),
        0 0.4rem 1.6rem var(--palette-h0s0l20a080);
      position: absolute;
      width: 14rem;
      height: 4rem;
      padding: 0.6rem 1.6rem;
      justify-content: center;
      @media (max-width: ${tablet}px) {
        display: none;
      }

      &--ec {
        left: calc(50% - 2rem);
        top: calc(50% + 18rem);
      }

      &--pe {
        left: calc(50% - 2rem);
        top: calc(50% + 24rem);
      }

      &--ar {
        width: 16rem;
        left: calc(50% - 4rem);
        top: calc(50% + 36rem);
      }

      &--mx {
        left: calc(50% - 16rem);
        top: calc(50% + 2rem);
      }

      &--col {
        width: 16rem;
        left: calc(50% - 6rem);
        top: calc(50% + 12rem);
      }
    }

    &__locationEllipse {
      margin-left: 1.2rem;
    }

    &__name {
      font-weight: 400;
      font-size: 1.8rem;
    }

    &__helper {
      width: 0.1rem;
      height: 80rem;
    }
  }
`;

export default CountryStyled;
