import React from "react";
import { Button } from "artisn-ui-react";
import { useRouter } from "next/router";

import Styles from "./LandingNavbar.styles";
import { LandingNavbarProps as Props } from "./LandingNavbar.types";
import Logo from "../Logo/Logo";
import useI18n from "hooks/useI18n";
import ButtonMenu from "../ButtonMenu/ButtonMenu";
import useAuth from "contexts/auth/auth.context.hooks";
import useCompany from "contexts/company/company.hooks";
import { useFetchCompaniesByUser } from "services/atn/company/company.service.hooks";
import { useFetchCurrentUser } from "services/atn/user/user.service.hooks";
import useSignOut from "hooks/useSignOut";

import LogOutSVG from "../../../../public/assets/images/log-in.svg";

const LandingNavbar: React.FC<Props> = props => {
  const t = useI18n().global.LandingNavbar;
  const { push, replace } = useRouter();
  const { isAnonymous } = useAuth();
  const { data: currentUser, isLoading } = useFetchCurrentUser();
  const { userType } = currentUser ?? {};
  const { data: companies } = useFetchCompaniesByUser();
  const { chooseCompany } = useCompany();
  const signOut = useSignOut();

  const dashboardHandler = () => {
    push("company");
  };

  const startHandler = () => {
    if (isAnonymous) {
      push("signin");
    } else if (userType === "ADMIN") {
      push("admin");
    } else if (companies?.length === 1) {
      chooseCompany(companies[0]);
      push("dashboard");
    } else {
      push("select-company");
    }
  };

  const singOutHandler = async () => {
    await signOut();
    replace("/");
  };

  const navigateProducts = () => {
    push("products");
  };

  const navigateAlliance = () => {
    push("alliance");
  };

  const LandingNavbarNode = (
    <>
      <div className="LandingNavbar__buttonsLink">
        <Button
          onClick={navigateProducts}
          type="LINK"
          className="LandingNavbar__button--link"
        >
          {t.prices}
        </Button>
        <Button
          type="LINK"
          className="LandingNavbar__button--link"
          onClick={navigateAlliance}
        >
          {t.alliances}
        </Button>
      </div>
      <Button
        className="LandingNavbar__button LandingNavbar__button--start"
        onClick={startHandler}
      >
        {t.start}
      </Button>
      {userType === "ADMIN" ? null : (
        <Button
          className="LandingNavbar__button LandingNavbar__button--dashboard"
          onClick={dashboardHandler}
        >
          {t.create}
        </Button>
      )}
    </>
  );

  return (
    <Styles className="LandingNavbar">
      <div className="LandingNavbar__content">
        <ButtonMenu className="LandingNavbar__menu" darkMode>
          <Logo darkMode className="MenuDrawer__logo" />
          <div className="MenuDrawer__actions">{LandingNavbarNode}</div>
        </ButtonMenu>
        <Logo darkMode />
        <div className="LandingNavbar__actions">{LandingNavbarNode}</div>
      </div>
      {!isAnonymous && !isLoading && (
        <div className="LandingNavbar__logOut" onClick={() => singOutHandler()}>
          <LogOutSVG />
        </div>
      )}
    </Styles>
  );
};

LandingNavbar.defaultProps = {};

export default LandingNavbar;
