import styled from "styled-components";

import { LandingHomeStyledProps as Props } from "./LandingHome.types";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const LandingHomeStyled = styled.div<Props>`
  min-width: var(--sizes-page-minWidth);
  max-width: var(--sizes-page-maxWidth);
  min-height: var(--sizes-page-minHeight);
  background-color: var(--palette-white);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-rows: max-content 1fr max-content;

  .LandingHome {
    &__header {
      grid-column: 1 / -1;
    }

    &__main {
      grid-column: 1 / -1;
    }

    &__footer {
      grid-column: 1 / -1;
      padding-top: 3.2rem;

      @media (max-width: ${mobile}px) {
        padding-top: 0;
      }
    }
  }
`;

export default LandingHomeStyled;
