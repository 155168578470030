import React from "react";

import Styles from "./MediumModal.styles";
import { MediumModalProps as Props } from "./MediumModal.types";

const Modal: React.FC<Props> = props => {
  const { discard } = props;
  return <Styles {...props} discard={discard} />;
};

Modal.defaultProps = {};

export default Modal;
