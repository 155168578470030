import React, { useState } from "react";
import { Button } from "artisn-ui-react";

import Styles, { MenuDrawerStyled } from "./ButtonMenu.styles";
import { ButtonMenuProps as Props } from "./ButtonMenu.types";

import CloseSVG from "../../../../public/assets/images/close.svg";

const ButtonMenu: React.FC<Props> = props => {
  const { className, children, darkMode = false } = props;
  const [showDrawer, setShowDrawer] = useState(false);

  const closeHandler = () => {
    setShowDrawer(false);
  };

  return (
    <Styles className={`ButtonMenu ${className}`} darkMode={darkMode}>
      <Button onClick={() => setShowDrawer(true)} className="ButtonMenu__menu">
        <div className="ButtonMenu__menu__line"></div>
        <div className="ButtonMenu__menu__line"></div>
        <div className="ButtonMenu__menu__line"></div>
      </Button>
      <MenuDrawerStyled
        className="MenuDrawer"
        opened={showDrawer}
        onClose={closeHandler}
        darkMode={darkMode}
      >
        <CloseSVG className="MenuDrawer__close" onClick={closeHandler} />
        {children}
      </MenuDrawerStyled>
    </Styles>
  );
};

ButtonMenu.defaultProps = {
  className: ""
};

export default ButtonMenu;
