import { Button } from "artisn-ui-react";
import React, { useState } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import useI18n from "hooks/useI18n";

import Styles from "./VideoSection.styles";
import { VideoSectionProps as Props } from "./VideoSection.types";
import VideoModal from "./VideoModal/VideoModal";

import DashboardPNG from "../../../../public/assets/images/dashboard.png";
import PlaySVG from "../../../../public/assets/images/Play.svg";

const VideoSection: React.FC<Props> = props => {
  const t = useI18n().landingHome.VideoSection;
  const [opened, setOpened] = useState(false);
  const { push } = useRouter();
  const isAnonymous = false;

  const companyHandler = () => {
    push("company");
  };

  return (
    <Styles className="VideoSection" anon={!!isAnonymous}>
      <VideoModal opened={opened} onClose={() => setOpened(false)} />
      <div className="VideoSection__content">
        <div className="VideoSection__instruction">
          <h2 className="VideoSection__title">{t.title}</h2>
          <p className="VideoSection__description">{t.description}</p>

          <Button onClick={companyHandler} className="VideoSection__button">
            {t.button}
          </Button>
          {isAnonymous && (
            <video
              className="VideoSection__video"
              width="50%"
              height="50%"
              autoPlay
              playsInline
              controls
            >
              <source
                type="video/mp4"
                src="https://proyectosinternos.s3.us-east-2.amazonaws.com/ATN-comofunciona.mp4"
              ></source>
            </video>
          )}
        </div>
        {!isAnonymous && (
          <div className="VideoSection__video">
            <Image src={DashboardPNG} alt="dashboard video" />
            <div className="VideoSection__play" onClick={() => setOpened(true)}>
              <PlaySVG />
            </div>
          </div>
        )}
      </div>
    </Styles>
  );
};

VideoSection.defaultProps = {};

export default VideoSection;
