import styled from "styled-components";

import { LandingNavbarStyledProps as Props } from "./LandingNavbar.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const LandingNavbarStyled = styled.div<Props>`
  padding: 3.2rem 7.2rem;
  background: var(--palette-h265s10l15a1);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${tablet}px) {
    padding: 3.2rem 1.6rem;
  }

  .LandingNavbar {
    &__actions {
      display: flex;

      @media (max-width: ${tablet}px) {
        display: none;
      }

      & > * {
        margin-right: 1.6rem;
      }
    }

    &__button {
      border-radius: 0.8rem;
      padding: 1.6rem 2rem;

      &--start {
        border: 0.1rem solid var(--palette-h15s15l95);
        background: var(--palette-h265s10l15a1);
        color: var(--palette-h15s15l95);
      }

      &--link {
        color: var(--palette-h0s0l95a1);
        font-size: 1.6rem;
        font-weight: 600;
        padding: 0;
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @media (max-width: ${tablet}px) {
        justify-content: flex-start;
      }
    }

    &__logOut {
      display: none;
      background-color: var(--palette-white);
      border-radius: 0.8rem;
      @media (max-width: ${tablet}px) {
        display: flex;
      }
      padding: 1.2rem 1.2rem;
    }

    &__buttonsLink {
      display: flex;

      & > * {
        margin-right: 2.4rem;
      }
    }
  }

  .Button__wrapper {
    font-weight: 600;
  }
`;

export default LandingNavbarStyled;
