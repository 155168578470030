import React from "react";
import Link from "next/link";

import Styles from "./Logo.styles";
import { LogoProps as Props } from "./Logo.types";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";

import LeafSVG from "../../../../public/assets/images/leaf.svg";

const { TITLE } = CONSTANTS;

const Logo: React.FC<Props> = props => {
  const t = useI18n().Logo;
  const { className, onClick, darkMode } = props;
  const label = `${TITLE} logo`;

  return (
    <Styles className={`Logo ${className}`} darkMode={darkMode}>
      {onClick ? (
        <div onClick={onClick} aria-label={label} className="Logo__container">
          <LeafSVG className="Logo__svg" />
          <h2 className="Logo__title">{t.title}</h2>
        </div>
      ) : (
        <Link href="/" passHref aria-label={label}>
          <div className="Logo__container">
            <LeafSVG className="Logo__svg" />
            <h2 className="Logo__title">{t.title}</h2>
          </div>
        </Link>
      )}
    </Styles>
  );
};

Logo.defaultProps = {
  className: ""
};

export default Logo;
