import styled from "styled-components";

import { LogoStyledProps as Props } from "./Logo.types";

const LogoStyled = styled.div<Props>`
  cursor: pointer;

  .Logo {
    &__title {
      padding-left: 0.8rem;
      font-weight: 500;
      font-size: 1.4rem;
      ${props => (props.darkMode ? `color: var(--palette-white)` : "")};
    }

    &__container {
      display: flex;
      flex: 1;
      align-items: center;
    }

    &__svg {
      & > path {
        ${props => (props.darkMode ? `fill: var(--palette-white)` : "")};
      }
    }
  }
`;

export default LogoStyled;
