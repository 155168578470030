import styled from "styled-components";

import { InstructionStyledProps as Props } from "./Instruction.types";
import CONSTANTS from "config/constants";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const InstructionStyled = styled.div<Props>`
  padding: 4.8rem 7.4rem;
  display: flex;
  justify-content: space-between;
  grid-gap: 1.6rem;
  ${props => (props.reverse ? "flex-direction: row-reverse;" : "")}
  flex-wrap: wrap;

  @media (max-width: ${tablet}px) {
    flex-direction: column-reverse;
    padding: 6.4rem 0;
  }

  .Instruction {
    &__title {
      font-size: 3.4rem;
      color: var(--palette-black);

      @media (max-width: ${tablet}px) {
        font-size: 2.4rem;
        padding-bottom: 2rem;
      }
    }

    &__description {
      color: var(--palette-h0s0l40a1);
      text-align: justify;
    }

    &__button {
      font-size: 1.6rem;
      font-weight: 600;
      color: var(--palette-primary);
      padding: 1.6rem 0;
    }

    &__instructionContainer {
      display: flex;
      flex: 1;
      justify-content: ${props => (props.reverse ? "flex-end" : "flex-start")};
      align-items: center;

      @media (max-width: ${tablet}px) {
        justify-content: center;
      }
    }

    &__instruction {
      max-width: 51.2rem;
      min-width: 32rem;

      @media (max-width: ${mobile}px) {
        min-width: unset;
        max-width: 100%;
        padding: 0 1.6rem;
      }
    }

    &__left {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }

    &__imageContainer {
      min-width: 32rem;
      max-width: 38.4rem;
      aspect-ratio: 4/5;
    }
  }
`;

export default InstructionStyled;
