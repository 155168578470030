import { Button } from "artisn-ui-react";
import React from "react";
import Image from "next/image";
import { useRouter } from "next/router";

import Styles from "./Instruction.styles";
import { InstructionProps as Props } from "./Instruction.types";
import useI18n from "hooks/useI18n";

const Instruction: React.FC<Props> = props => {
  const { reverse, alt, src, title, description } = props;
  const t = useI18n();
  const { push } = useRouter();

  const companyHandler = () => {
    push("company");
  };

  return (
    <Styles className="Instruction" reverse={reverse}>
      <div className="Instruction__instructionContainer">
        <div className="Instruction__instruction">
          <h2 className="Instruction__title">{title}</h2>
          <div className="Instruction__description">{description}</div>

          <Button
            onClick={companyHandler}
            type="LINK"
            className="Instruction__button"
          >
            {t.createBusiness}
          </Button>
        </div>
      </div>
      <div className="Instruction__left">
        <div className="Instruction__imageContainer">
          <Image src={src} alt={alt} className="Instruction__image" />
        </div>
      </div>
    </Styles>
  );
};

Instruction.defaultProps = {};

export default Instruction;
