import styled from "styled-components";

import { StepInfoStyledProps as Props } from "./StepInfo.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const StepInfoStyled = styled.div<Props>`
  max-width: 30rem;
  padding: 2.4rem 2.8rem;
  border: 0.1rem solid var(--palette-h0s0l100a1);
  border-radius: 3.2rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media (max-width: ${tablet}px) {
    max-width: 100%;
  }

  .StepInfo {
    &__title {
      padding-top: 4.8rem;
      padding-bottom: 0.4rem;
      font-weight: 500;
      font-size: 2rem;
      color: var(--palette-h0s0l95a1);
      text-align: start;
      @media (max-width: ${tablet}px) {
        padding-top: 2.4rem;
      }
    }

    &__subtitle {
      font-weight: 500;
      font-size: 1.2rem;
      color: var(--palette-h0s0l95a1);
      text-align: initial;
    }
  }
`;

export default StepInfoStyled;
