import { useContext } from "react";

import { CompanyContext } from "./company.context";
import { CompanyProviderValue } from "./company.context.types";

const useCompany = () => {
  const context = useContext<CompanyProviderValue>(CompanyContext);
  if (typeof context === "undefined") {
    throw new Error("useCompany must be used within a CompanyProvider");
  }
  return context;
};

export default useCompany;
