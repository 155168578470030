import React from "react";

import Styles from "./VideoModal.styles";
import { VideoModalProps as Props } from "./VideoModal.types";
import MediumModal from "components/global/MediumModal/MediumModal";
import CloseSVG from "../../../../../public/assets/images/close.svg";

const VideoModal: React.FC<Props> = props => {
  const { opened, onClose } = props;

  return (
    <MediumModal discard={false} {...props} opened={opened}>
      <Styles className="VideoModal">
        <video
          width="100%"
          height="100%"
          src="https://proyectosinternos.s3.us-east-2.amazonaws.com/videoLanding.mp4"
          autoPlay
          controls
          muted
          loop
        ></video>
        <div className="VideoModal__closer" onClick={onClose}>
          <CloseSVG />
        </div>
      </Styles>
    </MediumModal>
  );
};

VideoModal.defaultProps = {};

export default VideoModal;
