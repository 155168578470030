import styled from "styled-components";

import { ProcessStepStyledProps as Props } from "./ProcessStep.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const ProcessStepStyled = styled.div<Props>`
  background-color: var(--palette-h265s10l15a1);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-areas: ". content .";

  .ProcessStep {
    &__content {
      grid-area: content;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 6.4rem 0;
    }

    &__titleContainer {
      max-width: 62rem;
    }

    &__title {
      color: var(--palette-h0s0l100a1);
      font-size: 3.4rem;
      padding-bottom: 0.8rem;
    }

    &__subtitle {
      color: var(--palette-h0s0l100a1);
      font-weight: 500;
      font-size: 1.4rem;
    }

    &__actions {
      display: flex;

      @media (max-width: ${tablet}px) {
        display: none;
      }

      & > *:not(:last-child) {
        margin-right: 1rem;
      }
    }

    &__webStep {
      @media (max-width: ${tablet}px) {
        display: none;
      }
    }

    &__mobileStep {
      display: none;
      @media (max-width: ${tablet}px) {
        display: grid;
      }
    }

    &__button {
      border-radius: 0.8rem;
      font-weight: 600;

      &--secondary {
        background-color: var(--palette-h15s15l95);
        color: var(--palette-h270s10l15a1);
        border: none;
      }
    }

    &__steps {
      padding: 4.2rem 0;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
      justify-content: center;
      grid-gap: 1.6rem;
      width: 100%;

      @media (max-width: ${tablet}px) {
        display: flex;
        gap: 6rem;
        flex-direction: column;
        align-items: center;
      }
    }
  }
`;

export default ProcessStepStyled;
