import styled from "styled-components";
import { Drawer } from "artisn-ui-react";

import { ButtonMenuStyledProps as Props } from "./ButtonMenu.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const ButtonMenuStyled = styled.div<Props>`
  display: none;

  @media (max-width: ${tablet}px) {
    display: block;
  }

  .ButtonMenu {
    &__menu {
      background-color: unset;
      border: unset;
      box-shadow: unset;
      display: block;

      &__line {
        width: 2rem;
        height: 0.2rem;
        background-color: ${props =>
          props.darkMode ? "var(--palette-white)" : "var(--palette-black)"};
        box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.1);
        position: relative;
        margin-left: 0;

        &:not(:last-child) {
          margin-bottom: 0.4rem;
        }
      }
    }
  }
`;

export const MenuDrawerStyled = styled(Drawer)<Props>`
  width: 100%;
  height: 100%;
  background-color: ${props =>
    props.darkMode
      ? "var(--palette-h265s10l15a1) !important"
      : "var(--palette-white) !important"};
  position: relative;

  .MenuDrawer {
    &__close {
      position: absolute;
      right: 2.8rem;
      top: 3.6rem;

      path {
        fill: ${props =>
          props.darkMode ? "var(--palette-white)" : "var(--palette-black)"};
      }
    }

    &__logo {
      padding: 9rem 1.6rem 6.4rem;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      padding: 0 1.6rem;
      padding-bottom: 6.4rem;
      flex: 1;
    }

    &__sidebar {
      padding-top: 9rem;
    }
  }

  .Button__wrapper {
    color: var(--palette-white);
    font-weight: 600;
  }

  .Button {
    &:not(:first-child) {
      margin-top: 0.8rem;
    }
  }

  .LandingNavbar__buttonsLink {
    flex: 1;
    .LandingNavbar__button--link {
      padding: 1.2rem 0;
    }
  }

  .LandingNavbar__button--start {
  }

  .LandingNavbar__button--dashboard {
  }

  .LandingNavbar__button {
    width: 100%;
    border-radius: 0.8rem;
    padding: 1.6rem 0;

    &--start {
      background-color: var(--palette-h0s0l90a1);
      .Button__wrapper {
        color: var(--palette-black);
      }
    }
  }
`;

export default ButtonMenuStyled;
