import React from "react";
import Head from "next/head";

import useI18n from "hooks/useI18n";
import LandingHome from "components/landingHome/LandingHome/LandingHome";

interface Props {}

const LandingHomePage = (props: Props) => {
  const t = useI18n();

  return (
    <>
      <Head>
        <title>{t.home.metaTitle}</title>
        <meta name="description" content={t.home.metaDescription} />
        <meta name="robots" content="index, follow" />
      </Head>
      <LandingHome />
    </>
  );
};

export default LandingHomePage;
