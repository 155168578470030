import { Button } from "artisn-ui-react";
import React from "react";
import { useRouter } from "next/router";

import Styles from "./ProcessStep.styles";
import { ProcessStepProps as Props } from "./ProcessStep.types";
import StepInfo from "./StepInfo/StepInfo";
import useI18n from "hooks/useI18n";

import PencilSVG from "../../../../public/assets/images/landing/pencil.svg";
import PaperSVG from "../../../../public/assets/images/landing/paper.svg";
import DashboardSVG from "../../../../public/assets/images/landing/dashboard.svg";

const ProcessStep: React.FC<Props> = props => {
  const t = useI18n().landingHome.ProcessStep;
  const { push } = useRouter();

  const companyHandler = () => {
    push("company");
  };

  const navigateProducts = () => {
    push("products");
  };

  return (
    <Styles className="ProcessStep">
      <div className="ProcessStep__content">
        <div className="ProcessStep__titleContainer">
          <h2 className="ProcessStep__title">{t.title}</h2>
          <p className="ProcessStep__subtitle">{t.subtitle}</p>
        </div>
        <div className="ProcessStep__steps">
          <StepInfo
            title={t.firstStepTitle}
            subtitle={t.firstStepInfo}
            icon={<PencilSVG />}
          />
          <StepInfo
            title={t.secondStepTitle}
            subtitle={t.secondStepInfo}
            icon={<PaperSVG />}
          />
          <StepInfo
            title={t.thirdStepTitle}
            subtitle={t.thirdStepInfo}
            icon={<DashboardSVG />}
          />
        </div>
        <div className="ProcessStep__actions">
          <Button
            className="ProcessStep__button ProcessStep__button--secondary"
            onClick={navigateProducts}
          >
            {t.secondaryButton}
          </Button>
          <Button className="ProcessStep__button" onClick={companyHandler}>
            {t.button}
          </Button>
        </div>
      </div>
    </Styles>
  );
};

ProcessStep.defaultProps = {};

export default ProcessStep;
