import React from "react";

import Styles from "./LandingHome.styles";
import { LandingHomeProps as Props } from "./LandingHome.types";
import Footer from "components/global/Footer/Footer";
import LandingNavbar from "components/global/LandingNavbar/LandingNavbar";
import VideoSection from "../VideoSection/VideoSection";
import InstructionsSection from "../InstructionsSection/InstructionsSection";
import ProcessStep from "../ProcessStep/ProcessStep";
import Country from "components/Country/Country";

const LandingHome: React.FC<Props> = props => {
  return (
    <Styles className="LandingHome">
      <header className="LandingHome__header">
        <LandingNavbar />
      </header>
      <main className="LandingHome__main">
        <VideoSection />
        <InstructionsSection />
        <ProcessStep />
        <Country />
      </main>
      <footer className="LandingHome__footer">
        <Footer />
      </footer>
    </Styles>
  );
};

LandingHome.defaultProps = {};

export default LandingHome;
