import React from "react";

import Styles from "./StepInfo.styles";
import { StepInfoProps as Props } from "./StepInfo.types";

const StepInfo: React.FC<Props> = props => {
  const { icon, title, subtitle } = props;

  return (
    <Styles className="StepInfo">
      {icon}
      <div className="StepInfo__title">{title}</div>
      <div className="StepInfo__subtitle">{subtitle}</div>
    </Styles>
  );
};

StepInfo.defaultProps = {};

export default StepInfo;
