import React from "react";

import Styles from "./InstructionsSection.styles";
import { InstructionsSectionProps as Props } from "./InstructionsSection.types";
import Instruction from "./Instruction/Instruction";
import useI18n from "hooks/useI18n";

import AppPNG from "../../../../public/assets/images/landing/app-example.png";
import ManagePNG from "../../../../public/assets/images/landing/app-management.png";
import BanksPNG from "../../../../public/assets/images/landing/app-banks.png";

const InstructionsSection: React.FC<Props> = props => {
  const t = useI18n().landingHome.InstructionSection;

  return (
    <Styles className="InstructionsSection">
      <div className="InstructionsSection__content">
        <Instruction
          title={t.firstTitle}
          description={
            <div>
              {t.firstDescription}
              <div className="InstructionsSection__secondParagraph">
                {t.firstDescriptionParagraph}
              </div>
            </div>
          }
          src={AppPNG}
          reverse
        />
        <Instruction
          title={t.secondTitle}
          description={
            <div>
              <p className="InstructionsSection__descriptionText">
                {t.secondDescription}
              </p>
              <p className="InstructionsSection__secondParagraph">
                {t.secondDescriptionParagraph}
              </p>
              <ul className="InstructionsSection__list">
                <li>{t.secondDescriptionItems.itemOne}</li>{" "}
                <li>{t.secondDescriptionItems.itemTwo}</li>
                <li>{t.secondDescriptionItems.itemThree}</li>
              </ul>
            </div>
          }
          src={ManagePNG}
        />
        <Instruction
          title={t.thirdTitle}
          description={
            <div>
              <p className="InstructionsSection__descriptionText">
                {t.thirdDescription}
              </p>
              <p className="InstructionsSection__secondParagraph">
                {t.thirdDescriptionParagraph}
              </p>
              <ul className="InstructionsSection__list">
                <li>{t.thirdDescriptionItems.item1}</li>
                <li>{t.thirdDescriptionItems.item2}</li>
                <li>{t.thirdDescriptionItems.item3}</li>
                <li>{t.thirdDescriptionItems.item4}</li>
                <li>{t.thirdDescriptionItems.item5}</li>
                <li>{t.thirdDescriptionItems.item6}</li>
                <li>{t.thirdDescriptionItems.item7}</li>
                <li>{t.thirdDescriptionItems.item8}</li>
              </ul>
            </div>
          }
          src={BanksPNG}
          reverse
        />
      </div>
    </Styles>
  );
};

InstructionsSection.defaultProps = {};

export default InstructionsSection;
