import styled from "styled-components";

import { FooterStyledProps as Props } from "./Footer.types";
import CONSTANTS from "config/constants";

const { mobile, tablet } = CONSTANTS.BREAKPOINTS;

const FooterStyled = styled.div<Props>`
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  background-color: var(--palette-h0s0l98a1);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-areas: ". content .";

  .Footer {
    &__content {
      grid-area: content;
    }

    &__top {
      display: flex;
      justify-content: space-between;
      padding-bottom: 4.6rem;
      border-bottom: 1px solid var(--palette-h0s0l90a1);

      @media (max-width: ${mobile}px) {
        flex-direction: column;
        padding-bottom: 2.4rem;

        .Logo__container {
          padding-top: 0.8rem;
          padding-bottom: 3.2rem;
        }
      }
    }

    &__linkWrapper {
      display: flex;

      & > *:not(:last-child) {
        margin-right: 2.4rem;
      }
    }

    &__linkColumn > *:not(:last-child) {
      margin-bottom: 1.6rem;
    }

    &__link {
      font-size: 1.6rem;
      color: var(--palette-primary);
      font-weight: 600;
      cursor: pointer;
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
      padding: 5.6rem 2.4rem;

      @media (max-width: ${mobile}px) {
        padding: 2.4rem 0;
        flex-direction: column-reverse;
        padding-bottom: 0.8rem;
      }
    }

    &__rights {
      @media (max-width: ${mobile}px) {
        padding-top: 1.6rem;
      }
    }

    &__selectLang {
      .ant-select-selector {
        border-radius: 5.4rem;
        padding: 0 2rem;
        min-width: 0rem;
        font-weight: 600;
        font-size: 1.6rem;
        color: var(--palette-h0s0l50a1);

        @media (max-width: ${tablet}px) {
          min-width: 0rem;
        }
      }
    }
  }
`;

export default FooterStyled;
